<template>
	<div class="flex items-center justify-center vld-parent">

    <!-- Loading animation -->
    <loading
      :active="isLoading"
      :is-full-page="false"
      :loader="'bars'"
      :color="'#00afd3'"
      :height="90"
      :width="90"
      :opacity="0.7"
    />

		<div class="max-w-xl w-full p-10 rounded-lg shadow-md bg-white">
			<h2 class="mb-10 text-center text-3xl leading-9 font-extrabold text-primary-500">
        Смена пароля
      </h2>

      <fade-transition :duration="{enter: 300, leave: 300}" mode="out-in">
        <p
          v-if="showMessage"
          class="p-2 w-full rounded-md text-white" 
          :class="[!showError ? 'bg-green-600 px-16 py-16' : 'bg-red-700']"
        >            
          <span v-if="!showError" class="block px-3 font-semibold pb-6">
            <font-awesome-icon icon="check" class="text-5xl p-2 mx-auto my-6 text-green-600 bg-white rounded-full" /><br>
            Пароль успешно изменён!
          </span>

          <span v-if="showError">
            <font-awesome-icon icon="times" class="mr-1" />
            Неверный пароль!
          </span>
        </p>
      </fade-transition>

			<form class="mt-2 max-w-full" @submit.prevent="editClick()" v-if="!showMessage || showError">

        <!-- Старый пароль -->
        <div class="relative flex mb-3">
          <div class="w-1/3 pl-3 pt-2 pr-6 text-gray-500 text-sm text-left">
            Старый пароль:
          </div>

          <input
            aria-label="Old password"
            id="oldPassword"
            v-model="oldPassword"
            @change="$v.oldPassword.$touch"
            v-bind:class="{ 'focus:border-red-500 text-red-700' : $v.oldPassword.$error,
              'focus:border-green-300 text-primary-500' : !$v.oldPassword.$error }"
            class="appearance-none relative block w-2/3 px-3 py-2 border border-gray-300 placeholder-gray-500
            text-gray-900 rounded-md focus:outline-none focus:shadow-outline-blue focus:z-10 sm:text-sm
            sm:leading-5"
            placeholder="Старый пароль"
          />

          <!-- Input field validation icon with animation -->
          <slide-x-left-transition :duration="300" mode="out-in">
            <div v-if="$v.oldPassword.$dirty" class="absolute right-0 top-0 mr-4 ml-auto mt-2">
              <font-awesome-icon v-if="!$v.oldPassword.$error" icon="check" class="text-green-700"/>
              <font-awesome-icon v-if="$v.oldPassword.$error" icon="times" class="text-red-700"/>
            </div>
          </slide-x-left-transition>

          <!-- Error message -->
          <collapse-transition :duration="300" mode="out-in">
            <div v-if="$v.oldPassword.$error" class="text-red-700 mt-0 text-sm">
              <span v-if="!$v.oldPassword.minLength || !$v.newPassword.required">Пароль должен быть минимум 6 символов!</span>
            </div>
          </collapse-transition>
        </div>

        <!-- Новый пароль -->
        <div class="mb-3 relative flex">
          <div class="w-1/3 pl-3 pt-2 pr-6 text-gray-500 text-sm text-left">
            Новый пароль:
          </div>
          <input
            aria-label="New password"
            id="newPassword"
            v-model="newPassword"
            @change="$v.newPassword.$touch"
            v-bind:class="{ 'focus:border-red-500 text-red-700' : $v.newPassword.$error,
              'focus:border-green-300 text-primary-500' : !$v.newPassword.$error }"
            class="appearance-none relative block w-2/3 px-3 py-2 border border-gray-300 placeholder-gray-500
            text-gray-900 rounded-md focus:outline-none focus:shadow-outline-blue focus:z-10 sm:text-sm
            sm:leading-5"
            placeholder="Новый пароль"
          />

          <!-- Input field validation icon with animation -->
          <slide-x-left-transition :duration="300" mode="out-in">
            <div v-if="$v.newPassword.$dirty" class="absolute right-0 top-0 mr-4 ml-auto mt-2">
              <font-awesome-icon v-if="!$v.newPassword.$error" icon="check" class="text-green-700"/>
              <font-awesome-icon v-if="$v.newPassword.$error" icon="times" class="text-red-700"/>
            </div>
          </slide-x-left-transition>

          <!-- Error message -->
          <collapse-transition :duration="300" mode="out-in">
            <div v-if="$v.newPassword.$error" class="text-red-700 mt-0 text-sm">
              <span v-if="!$v.newPassword.minLength || !$v.newPassword.required">Пароль должен быть минимум 6 символов!</span>
            </div>
          </collapse-transition>
        </div>

        <!-- Новый пароль подтверждение -->
        <div class="mb-3 relative flex">
          <div class="w-1/3 pl-3 pt-2 pr-6 text-gray-500 text-sm text-left">
            Подтвердите пароль:
          </div>
          <input
            aria-label="Confirm password"
            id="confPassword"
            v-model="confPassword"
            @change="$v.confPassword.$touch"
            v-bind:class="{ 'focus:border-red-500 text-red-700' : $v.confPassword.$error,
              'focus:border-green-300 text-primary-500' : !$v.confPassword.$error }"
            class="w-2/3 appearance-none relative block px-3 py-2 border border-gray-300 placeholder-gray-500
            text-gray-900 rounded-md focus:outline-none focus:shadow-outline-blue focus:z-10 sm:text-sm
            sm:leading-5"
            placeholder="Подтвердите пароль"
          />

          <!-- Input field validation icon with animation -->
          <slide-x-left-transition :duration="300" mode="out-in">
            <div v-if="$v.confPassword.$dirty" class="absolute right-0 top-0 mr-4 ml-auto mt-2">
              <font-awesome-icon v-if="!$v.confPassword.$error" icon="check" class="text-green-700"/>
              <font-awesome-icon v-if="$v.confPassword.$error" icon="times" class="text-red-700"/>
            </div>
          </slide-x-left-transition>

          <!-- Error message -->
          <collapse-transition :duration="300" mode="out-in">
            <div v-if="$v.confPassword.$error" class="text-red-700 mt-0 text-sm">
              <span v-if="!$v.confPassword.sameAsPassword || !$v.confPassword.required">Пароли не совпадают!</span>
            </div>
          </collapse-transition>
        </div>

        <button 
          type="submit"
          class="group mr-4 mt-4 relative w-1/3 justify-center py-2 px-4 text-sm leading-5 font-medium rounded-md
          text-white bg-primary-600 hover:bg-primary-400 active:bg-primary-600 transition duration-150 ease-in-out"
        >
          Сохранить
        </button>

        <button
          @click="$xmodal.close()"
          class="group mt-4 relative w-1/3 justify-center py-2 px-4 text-sm leading-5 font-medium rounded-md
          text-white bg-primary-600 hover:bg-primary-400 active:bg-primary-600 transition duration-150 ease-in-out"
        >
          Отмена
        </button>

			</form>
		</div>
	</div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { email, required, sameAs, minLength, requiredIf, helpers } from 'vuelidate/lib/validators'
import { SlideXLeftTransition, CollapseTransition } from 'vue2-transitions'

import { FadeTransition } from 'vue2-transitions'

import { mapState } from 'vuex'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
	name: 'EditPassModal',
  mixins: [validationMixin],
  components: { SlideXLeftTransition, CollapseTransition, Loading, FadeTransition },
	data: function() {
		return {
      oldPassword: null,
      newPassword: null,
      confPassword: null,
      isLoading: false,
      showError: false,
      showMessage: false
    }
	},
  validations: {
    oldPassword: {
      required,
      minLength: minLength(6)
    },
    newPassword: {
      required,
      minLength: minLength(6)
    },
    confPassword: {
      required,
      minLength: minLength(6),
      sameAsPassword: sameAs(function() {
          return this.newPassword
        }
      )
    }
  },
  methods: {
    editClick() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }

      this.showMessage = false
      this.isLoading = true
      const data = {
        old: this.oldPassword,
        new: this.newPassword
      }

      this.$store.dispatch('user/changePassword', data)
        .then(() => {
          this.showError = false
          setTimeout(() => { this.$xmodal.close() }, 500)
        })
        .catch((error) => {
          this.showError = true
        })
        .finally(() => {
          this.showMessage = true
          this.isLoading = false
        })
    }
  }
}
</script>